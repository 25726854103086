
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  emits: ["click"],
  props: {
    text: String,
    size: Number,
    maxSize: String,
    sortable: Boolean,
    select: Boolean,
    desc: Boolean,
    showOnMobile: Boolean
  }
})
export default class MTableHeaderCell extends Vue {
  text = "";
  size = 0;
  maxSize?: string;
  sortable = false;
  select = false;
  desc = false;
  showOnMobile = false;

  get textClass() {
    return !this.maxSize ? "break-all" : "truncate";
  }

  get wrapClass(): string {
    const classes: string[] = [];

    if (this.size === 0) {
      classes.push("flex-1");
    } else {
      classes.push("flex-none");
      classes.push(`w-${this.size}`);
    }

    if (this.maxSize) {
      classes.push(`max-w-${this.maxSize}`);
    }

    if (this.sortable) {
      classes.push("cursor-pointer");

      if (this.select) {
        classes.push("text-primary-500");
        classes.push("hover:text-primary-600");
      } else {
        classes.push("hover:text-primary-500");
      }
    }

    if (this.showOnMobile) {
      classes.push("flex");
    } else {
      classes.push("hidden");
    }

    return classes.join(" ");
  }

  get iconClass(): string {
    if (this.select && !this.desc) {
      return "transform rotate-180";
    } else {
      return "";
    }
  }

  click() {
    this.$emit("click");
  }
}


import MTableContents from "@/components/MTableContents.vue";
import MTableFooter from "@/components/MTableFooter.vue";
import MTableHeader from "@/components/MTableHeader.vue";
import { TableData, TableDefinition } from "@/utils/table";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MTableContents,
    MTableFooter,
    MTableHeader
  },
  emits: ["change", "select", "copy"],
  props: {
    datas: Array,
    definitions: Array,
    countPerPage: Number,
    order: Boolean,
    selectable: Boolean,
    filterableColumn: Boolean,
    selectedIds: Array,
    editable: Boolean,
    useActions: Boolean,
    useSubmission: Boolean,
    selectedColumnList: Array,
    coloredRowList: Array
  }
})
export default class MTable extends Vue {
  // props
  datas: TableData[] = [];
  definitions: TableDefinition[] = [];
  countPerPage = 20;
  order = false;
  selectable = false;
  filterableColumn = false;
  selectedIds: string[] = [];
  editable = false;
  useActions = false;
  useSubmission = false;
  selectedColumnList: number[] = [];
  coloredRowList: { key: string; color: string }[] = [];

  // datas
  sortIsDesc = true;
  sortKey = "";
  currentPagination = 0;

  get filteredDefinitions() {
    return !this.filterableColumn
      ? this.definitions
      : this.definitions.filter((value: TableDefinition, index: number) =>
          this.selectedColumnList.includes(index)
        );
  }

  get hasLink(): boolean {
    if (this.datas.length === 0) {
      return false;
    }

    if (!this.datas[0].link) {
      return false;
    } else {
      return true;
    }
  }

  get startIndex(): number {
    return this.countPerPage * this.currentPagination;
  }

  get endIndex(): number {
    const standardNum = this.countPerPage * (this.currentPagination + 1) - 1;
    if (standardNum < this.datas.length) {
      return standardNum;
    } else {
      return this.datas.length - 1;
    }
  }

  get startNum(): number {
    if (this.datas.length === 0) {
      return 0;
    }

    return this.startIndex + 1;
  }

  get endNum(): number {
    return this.endIndex + 1;
  }

  get maxNum(): number {
    return this.datas.length;
  }

  get sortedDatas(): { [key: string]: string | number | boolean }[] {
    const datas = [...this.datas];
    if (!this.sortKey || datas.length === 0) {
      return datas;
    }

    const matchDefs = this.definitions.filter(def => def.key === this.sortKey);
    if (matchDefs.length === 0) {
      return datas;
    }
    const def = matchDefs[0];

    let isString: boolean;
    let key: string;
    if (def.sort === "normal") {
      key = this.sortKey;
      isString = def.type === "string" || def.type === "none";
    } else if (def.sort === "prefix") {
      key = `pre-${this.sortKey}`;
      isString = def.prefixType === "string" || def.prefixType === "none";
    } else if (def.sort === "suffix") {
      key = `suf-${this.sortKey}`;
      isString = def.suffixType === "string" || def.suffixType === "none";
    } else {
      return datas;
    }

    datas.sort((a, b) => {
      const aValue = a[key] ?? "";
      const bValue = b[key] ?? "";

      if (isString) {
        if (this.sortIsDesc) {
          return (bValue as string).localeCompare(aValue as string);
        } else {
          return (aValue as string).localeCompare(bValue as string);
        }
      } else {
        if (this.sortIsDesc) {
          return (bValue as number) - (aValue as number);
        } else {
          return (aValue as number) - (bValue as number);
        }
      }
    });

    return datas;
  }

  get datasWithOrder(): { [key: string]: string | number | boolean }[] {
    const datas: { [key: string]: string | number | boolean }[] = [];
    for (const i in this.sortedDatas) {
      const index = Number(i);
      const order = index + 1;
      const data = this.sortedDatas[index];
      data.order = order;
      data.select = this.selectedIds.includes(data.id as string);
      datas.push(data);
    }
    return datas;
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    return this.datasWithOrder.slice(this.startIndex, this.endNum);
  }

  get selectedAll(): boolean {
    if (!this.selectable) {
      return false;
    }

    return (
      this.filteredDatas.filter(
        data => !this.selectedIds.includes(data.id as string)
      ).length === 0
    );
  }

  changeSort(key: string) {
    if (this.sortKey === key) {
      this.sortIsDesc = !this.sortIsDesc;
    } else {
      this.sortKey = key;
      this.sortIsDesc = true;
    }

    this.currentPagination = 0;
  }

  backPagination() {
    if (this.currentPagination == 0) {
      return;
    }
    this.currentPagination -= 1;
  }

  nextPagination() {
    if (this.endNum >= this.maxNum) {
      return;
    }
    this.currentPagination += 1;
  }

  change(id: string) {
    this.$emit("change", id);
  }

  select(id: string) {
    this.$emit("select", id);
  }

  copy(id: string) {
    this.$emit("copy", id);
  }

  changeSelectAll() {
    const unSelectedIds = this.filteredDatas
      .map(data => data.id as string)
      .filter(id => !this.selectedIds.includes(id));
    if (unSelectedIds.length > 0) {
      unSelectedIds.forEach(id => this.change(id));
    } else {
      this.filteredDatas
        .map(data => data.id as string)
        .forEach(id => this.change(id));
    }
  }
}


import MTableHeaderCell from "@/components/MTableHeaderCell.vue";
import { TableDefinition } from "@/utils/table";
import { Options, Vue } from "vue-class-component";

function convertToData(
  definition: TableDefinition,
  sortKey: string,
  desc: boolean
): DataType {
  return {
    key: definition.key,
    text: definition.text,
    size: definition.size,
    maxSize: definition.maxSize,
    sortable: definition.sort !== "disable",
    select: definition.key === sortKey,
    desc,
    showOnMobile: definition.showOnMobile
  };
}

type DataType = {
  key: string;
  text: string;
  size: number;
  maxSize?: string;
  sortable: boolean;
  select: boolean;
  desc: boolean;
  showOnMobile: boolean;
};

@Options({
  components: {
    MTableHeaderCell
  },
  emits: ["change", "select"],
  props: {
    definitions: Array,
    sortKey: String,
    desc: Boolean,
    order: Boolean,
    hasLink: Boolean,
    selectable: Boolean,
    editable: Boolean,
    useActions: Boolean,
    useSubmission: Boolean,
    selectedAll: Boolean
  }
})
export default class MTableHeader extends Vue {
  definitions: TableDefinition[] = [];
  sortKey = "";
  desc = false;
  order = false;
  hasLink = false;
  selectable = false;
  editable = false;
  useActions = false;
  useSubmission = false;
  selectedAll = false;

  get datas(): DataType[] {
    return this.definitions.map(def =>
      convertToData(def, this.sortKey, this.desc)
    );
  }

  change(data: DataType) {
    if (!data.sortable) {
      return;
    }

    this.$emit("change", data.key);
  }

  select() {
    this.$emit("select");
  }
}

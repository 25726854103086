
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MIcon
  },
  emits: ["back", "next"],
  props: {
    start: Number,
    end: Number,
    max: Number
  }
})
export default class MTableFooter extends Vue {
  start = 0;
  end = 0;
  max = 0;
  back() {
    this.$emit("back");
  }

  next() {
    this.$emit("next");
  }
}
